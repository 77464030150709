<template>
  <div style="min-height: calc(100vh - 20px)" v-show="is_check">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh; " align-v="center">
            <b-col class="d-lg-flex align-items-center justify-content-center">
              <b-row class="justify-content-center" v-if="expire">
                <b-col>
                  <h3 class="font-weight-bold mb-4">{{ $t('changePassword.expire') }}</h3>
                  <h5 class="font-weight-bold mb-4">{{ $t('changePassword.resendAgain') }}</h5>
                  <b-button
                      variant="success"
                      pill
                      block
                      class="mt-4 mb-3"
                      @click="backToLogin"
                  >{{ $t('forgotPassword.backToLogin') }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="justify-content-center" v-else>
                <b-col>
                  <h2 class="font-weight-bold mb-4">{{ $t('changePassword.title') }}</h2>
                  <h5 class="mb-3">{{ $t('changePassword.detail') }}</h5>

                  <b-form>
                    <b-form-group>
                      <b-form-input
                          id="txt-username"
                          type="password"
                          :placeholder="$t('changePassword.newPassword')"
                          v-model="$v.form.password.$model"
                          :state="validateState('password')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="txt-username">
                        {{changeErrorFromRequire($v.form.password)}}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group>
                      <b-form-input
                          id="txt-username"
                          type="password"
                          :placeholder="$t('changePassword.confirmPassword')"
                          v-model="$v.form.confirm_password.$model"
                          :state="validateState('confirm_password')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="txt-username">
                        {{changeErrorFromRequire($v.form.confirm_password)}}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-button
                        variant="gradient"
                        :disabled="$v.form.$invalid"
                        pill
                        block
                        class="mt-4 mb-3"
                        @click="changePassword"
                    >{{ $t('changePassword.button') }}
                      <b-spinner small v-show="changeSuccess"/>
                    </b-button>
                    <label style="color: #FF3B3B">{{ errLabel }}</label>
                  </b-form>
                </b-col>
              </b-row>

            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import Language from "@/components/common/Language";
import profileApi from "@/repository/profileApi";
import alert from "@/common/alert";

export default {
  name: "Login",
  components: {Language},
  mixins: [validationMixin],
  data: () => ({
    form: {
      password: "",
      confirm_password: "",
    },
    is_check: false,
    changeSuccess: false,
    errLabel: "",
    expire: true,
  }),
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      confirm_password: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs('password')
      }
    },
  },
  created() {
    this.checkURL()
  },
  methods: {
    checkURL() {
      if (this.$route.query.token) {
        const data = {
          token: this.$route.query.token
        }
        profileApi.verifyResetPassword(data).then(response => {
          if (response.codeSYS === "001" && response.allow_reset_password) {
            localStorage.setItem('token', this.$route.query.token)
            this.expire = false
          } else {
            this.expire = true
          }
        }).catch(err => {
          this.expire = true
        }).finally(() => {
          this.is_check = true
        })
      } else {
        this.expire = true
      }
    },
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    backToLogin() {
      this.$router.push('Login').catch(() => {
      })
    },
    changePassword() {
      this.changeSuccess = true
      const data = {
        password: this.form.password
      }
      profileApi.resetPassword(data).then(response => {
        if (response.codeSYS === "001") {
          this.changeSuccess = false
          alert.updateSuccess(this)
          setTimeout(() => this.backToLogin(), 1100)
        } else {
          this.errLabel = response.message
        }
      }).catch(err => {
        this.errLabel = err.response.data.message
      })
    },
    changeErrorFromRequire(event) {
      if (!event.minLength) {
        return this.$t('common.password_length')
      }else if (!event.sameAsPassword && this.form.password !== '' && this.form.confirm_password !== '') {
        return this.$t('common.notSameAsPassword')
      }else {
        return this.$t('common.requiredField')
      }
    }
  },
};
</script>
<style>
.logo-login {
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 4px 0px 4px 0px rgba(148, 149, 155, 0.55) !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #3e5569;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
</style>
